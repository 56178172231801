$primary-color: #f27a43; //#24b8c0
$secondary-color: #afafaf; //#f634f2
$danger-color: #ff0000;
$dark-color: #000000;
$highlight-color: #dcc97e;

$light-color: #ffffff;
$btn-primary-color: linear-gradient(270deg, #ed541d 0%, #f4901b 100%);

$sm-screen: '640px';
$md-screen: '768px';
$lg-screen: '1024px';
$xl-screen: '1280px';
$xxl-screen: '1536px';

//fonts
$font-gkktt: '851ゴチカクット';
