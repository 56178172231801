//import bootstrap functions,mixins and variables
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/variables";

//Create custom colors
@import "color";

//Create custom spacer
@import "spacer";

//enable default negative margin
$enable-negative-margins: true;

//Create custom width and height
@import "width_height";

//import bootstrap
@import "../../../../node_modules/bootstrap/scss/bootstrap";

p {
  margin: 0;
}

.w-29 {
  width: 29%;
}
