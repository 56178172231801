.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
  }
}

.video-background::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
  position: absolute;
  top: 0;
  left: 0;
}

.generate-loading {
  .message-box {
    .container {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}