.switch[data-id] {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #575757;
    border-radius: 20px;
    transition: background-color 0.2s;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.2s;
    }
  }

  input:checked + label {
    background-color: #00be85;
  }

  input:checked + label::before {
    transform: translateX(20px);
  }
}
