@use "./variables.scss" as var;

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track (background) */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
html {
  scrollbar-color: #888; /* thumb */
  scrollbar-width: thin;
}

/* Internet Explorer & Old Edge (Optional: Only works in older versions) */
body {
  scrollbar-face-color: #888;
}

/** bootstrap custom config ****
/*
    color => ~/src/assets/scss/bootstrap/color.scss
    margin,padding and spacing => ~/src/assets/scss/bootstrap/spacer.scss
    width and height => ~/src/assets/scss/bootstrap/width_height.scss
*/

/** Generate custom font sizes */
/**
    Example Usage (you can use within 1px to 50 px)
    eg. fs-1-px, fs-20-px ...... fs-50-px
*/
@for $i from 1 through 50 {
  .fs-#{$i}-px {
    font-size: 1px * $i !important;
  }
}
@for $i from 1 through 50 {
  .hover\:fs-#{$i}-px {
    &:hover {
      font-size: 1px * $i !important;
    }
  }
}

@for $i from 0 through 50 {
  .p-#{$i}-px {
    padding: 1px * $i !important;
  }

  .pl-#{$i}-px {
    padding-left: 1px * $i !important;
  }

  .pr-#{$i}-px {
    padding-right: 1px * $i !important;
  }

  .pt-#{$i}-px {
    padding-top: 1px * $i !important;
  }

  .pb-#{$i}-px {
    padding-bottom: 1px * $i !important;
  }

  .px-#{$i}-px {
    padding-inline: 1px * $i !important;
  }

  .py-#{$i}-px {
    padding-block: 1px * $i !important;
  }

  .m-#{$i}-px {
    margin: 1px * $i !important;
  }

  .ml-#{$i}-px {
    margin-left: 1px * $i !important;
  }

  .mr-#{$i}-px {
    margin-right: 1px * $i !important;
  }

  .mt-#{$i}-px {
    margin-top: 1px * $i !important;
  }

  .mb-#{$i}-px {
    margin-bottom: 1px * $i !important;
  }

  .mx-#{$i}-px {
    margin-inline: 1px * $i !important;
  }

  .my-#{$i}-px {
    margin-block: 1px * $i !important;
  }
}

.ml-150-px {
  margin-left: 150px;
}

.ml-100-px {
  margin-left: 100px;
}

@mixin primary-gradient {
  background: rgb(245, 151, 27);
  background: linear-gradient(
    90deg,
    rgba(245, 151, 27, 1) 0%,
    rgba(236, 77, 29, 1) 100%
  );
}

/** End generate custom font sizes */

//write your class
.pt-1\/2 {
  padding-top: 0.5px !important;
}
.required-label {
  position: relative;
  right: 0;
  top: 0.2rem;
}

%unset-input {
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  // -webkit-appearance: none;
}

/** font family */

//notosan

@font-face {
  font-family: "NotoSansJP";
  src: url("../fonts/notosansjp/NotoSansJP-Regular.otf");
}

@font-face {
  font-family: "NotoSansJP-Medium";
  src: url("../fonts/notosansjp/NotoSansJP-Medium.otf");
}

@font-face {
  font-family: "NotoSansJP-light";
  src: url("../fonts//notosansjp/NotoSansJP-Light.otf");
}

.font-notosans-jp {
  font-family: "NotoSansJP" !important;
}

.font-notosans-jp-medium {
  font-family: "NotoSansJP-Medium" !important;
}

.font-notosans-jp-light {
  font-family: "NotoSansJP-light" !important;
}

// font 851GKKTT
@font-face {
  font-family: "851ゴチカクット";
  src: url("../fonts/851Gkktt_005.ttf");
}

.font-gkktt {
  font-family: "851ゴチカクット" !important;
}

//end notosan

@font-face {
  font-family: "Ab-j_choki";
  src: url("../fonts/Ab-j_choki.ttf") format("truetype");
}

.font-abj-choki {
  font-family: "Ab-j_choki" !important;
}

@font-face {
  font-family: "Ethno";
  src: url("../fonts/ethnocentric/Ethnocentric.otf");
}

.font-ethno {
  font-family: "Ethno" !important;
  // font-weight: ;
}

// @font-face {
//   font-family: "ethno-light";
//   src: url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("woff2"),
//     url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("woff"),
//     url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("opentype");
//   font-display: auto;
//   font-style: normal;
//   font-weight: 300;
//   font-stretch: normal;
// }

@font-face {
  font-family: "ethnocentric";
  src: url("https://use.typekit.net/af/03258e/000000000000000077359660/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/03258e/000000000000000077359660/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/03258e/000000000000000077359660/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

.font-ethno-normal {
  font-family: "ethnocentric" !important;
  font-weight: 900;
}

@font-face {
  font-family: "ethno-light";
  src: url("../fonts/ethnocentric/Ethnocentric\ Light.otf");
}
.font-ethno-light {
  font-family: "ethno-light" !important;
  font-weight: 300;
}

.font-ethno-bold {
  font-family: "ethno-light" !important;
  font-weight: 900;
}

@font-face {
  font-family: "Europa";
  src: url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot");
  src: url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.svg#Europa")
      format("svg");
}

// @font-face {
//   font-family: "Europa";
//   src: url("../fonts/europa.ttf");
// }
.font-europa-regular {
  font-family: "Europa" !important;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../fonts/Hiragino Sans.otf");
  // src: url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.eot");
  // src: url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.eot?#iefix")
  //     format("embedded-opentype"),
  //   url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.woff2")
  //     format("woff2"),
  //   url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.woff")
  //     format("woff"),
  //   url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.ttf")
  //     format("truetype"),
  //   url("https://db.onlinewebfonts.com/t/bcf863a2d0e43d0a7d99cd7b5bdcedd5.svg#Hiragino Sans GB W05 W4")
  //     format("svg");
}
.font-hiragino-sans {
  font-family: "Hiragino Sans" !important;
}

/** end font family */

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

/** colors */
.text-gray {
  color: #b4b4b4 !important;
}
.text-dark-gray {
  color: #9a9a9a !important;
}
.text-highlight {
  color: var.$highlight-color !important;
}
.text-light {
  color: var.$light-color !important;
}
.text-light-primary {
  color: #ff996a !important;
}
/** end colors */

/** Menu Button Classes */
.menu-button-container {
  gap: 60px;
  // @media (min-width: var.$lg-screen) {
  //   gap: 24px;
  // }
  // @media (min-width: var.$xl-screen) {
  //   gap: 30px;
  // }
}
.bottom-menu-container {
  .button-menu-gap {
    gap: 22px;
    @media (min-width: var.$xl-screen) {
      gap: 36px;
    }
  }
}

.menu-button {
  --path: 90% 10%, 100% 35%, 100% 70%, 90% 90%, 10% 90%, 0% 70%, 0% 35%, 10% 10%;
  --left-path: polygon(
    79% 9%,
    99% 10%,
    19% 33%,
    18% 69%,
    98% 91%,
    79% 92%,
    0% 69%,
    -1% 32%
  );

  width: 200px;

  .outside {
    position: relative;
    width: 173px;
    height: 55px;
    background: #ed753e;
    clip-path: polygon(var(--path));
  }

  .inside {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: var.$dark-color;
    clip-path: polygon(var(--path));
  }

  .left {
    width: 20px;
    height: 55px;
    margin-right: -12px;
    background: #ed753e;
    clip-path: var(--left-path);
  }

  .right {
    width: 20px;
    height: 55px;
    margin-left: -12px;
    background: #ed753e;
    clip-path: var(--left-path);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .text {
    color: white;
  }
}

.menu-button:hover {
  .outside {
    background: var.$secondary-color;
    cursor: pointer;
  }

  .left {
    background: var.$secondary-color;
  }

  .right {
    background: var.$secondary-color;
  }

  .text {
    color: var.$secondary-color;
  }
}

.outline-btn {
  $input-path: calc(100% - 12px) 100%, 100% calc(100% - 12px), 100% 12px,
    calc(100% - 12px) 0%, 12px 0%, 0% 12px, 0% calc(100% - 12px), 12px 100%;
  $border-width: 1.5px;
  // all: unset;
  position: relative;
  min-width: 100px;
  height: 41px;
  width: 230px;
  .bg {
    position: absolute;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: rgba(#7a7373, 0.5);
    clip-path: polygon($input-path);

    &::after {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: $border-width;
    }
  }

  button {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    color: var.$highlight-color;
    font-family: "ethnocentric";
    font-size: 20px;
    text-align: center;
    padding: 0px 35px 0px 20px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  .right-icon {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  &:hover {
    .bg {
      background-color: #24b8c0;
    }

    path {
      opacity: 1;
    }
  }
}

/** End Menu Button Classes */

/** content */
@mixin content(
  $cb-width: 2px,
  //content-border-width
  $border-radius: 12px,

  //corner radius
  /* title space */ $title-width: 270px,

  //317
  $title-height: 45px,
  $title-slop: 46px,
  $title-slope-adjust-point: 1px,
  /* end title space */ /*bottom space */ $bottom-space-width: 340px,
  $bottom-space-height: 40px,
  $bottom-space-slope: 25px,
  $bottom-left-width: calc((100% - $bottom-space-width) / 2)
) {
  /* clip path variables */

  // $cb-width: 2px; //content-border-width
  // $border-radius: 12px; //corner radius

  // /* title space */
  // $title-width: 267px; //317

  // $title-height: 50px;
  // $title-slop: 50px;
  // $title-slope-adjust-point: 1px;
  // /* end title space */

  // /*bottom space */
  // $bottom-space-width: 340px;
  // $bottom-space-height: 40px;
  // $bottom-space-slope: 25px;
  // $bottom-left-width: calc((100% - $bottom-space-width) / 2);

  .content-bg-darker {
    background-color: rgba(var.$dark-color, 0.2);
  }

  /* end clip path variables */
  .content-border {
    background-color: rgba(var.$primary-color, 0.5);
    min-height: 200px;
    width: 100%;
    height: 100%;
    z-index: -1;

    /* Content Border Path */
    clip-path: polygon(
      evenodd,
      /**
        //origin clip path
        clip-path: polygon(evenodd, 0% 0%, 21.26% 0%, 32.24% 13.98%, 95.63% 13.98%, 100% 28.27%, 100% 89.74%, 95.63% 100%, 62.63% 100%, 53.9% 92.23%, 39.51% 92.3%, 32.24% 100%, 4% 100%, 7.8% 94.72%, 3.76% 84.99%, 3.9% 6.5%, 19.88% 6.5%, 30.02% 20.22%, 92.74% 20.22%, 95.75% 28.27%, 95.83% 88.04%, 92.74% 93.72%, 62.49% 94.72%, 54.62% 86.02%, 39.32% 85.71%, 32.24% 94.72%, 7.8% 94.72%, 4% 100%, 0% 88.99%);
        background-color: #6beb47;

        */
        /*Outer Path */ 0% 0%,
      /* 1 outer left start point*/ $title-width 0%,
      /* 2 outer (Title Width) */
        calc($title-width + $title-slop - $title-slope-adjust-point)
        $title-height + $title-slope-adjust-point,
      /* 3 outer Title Height */ calc(100% - $border-radius) $title-height,
      /* 4 outer right top width to end*/ 100%
        calc($title-height + $border-radius),
      /* 5 outer right top height*/ 100% calc(100% - $border-radius),
      /* 6 outer bottom height*/ calc(100% - $border-radius) 100%,
      /* 7 outer bottom width*/ calc(100% - $bottom-left-width) 100%,
      /* 8 outer bottom space*/
        calc(100% - $bottom-left-width - $bottom-space-slope - $cb-width)
        calc(100% - $bottom-space-height),
      /* 9 outer bottom space*/
        calc($bottom-left-width + $bottom-space-slope + $cb-width)
        calc(100% - $bottom-space-height),
      /* 10 outer bottom space*/ calc((100% - $bottom-space-width) / 2) 100%,
      /* 11 outer bottom space*/ $border-radius 100%,
      /* outer junction point */ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ calc($cb-width)
        calc(100% - $border-radius - $cb-width),
      /* end point */ /*Inner Path*/ $cb-width $cb-width,
      /* 1 inner left start point*/
        ($title-width - $cb-width + $title-slope-adjust-point) $cb-width,
      /* 2 inner (300px,Title Width */
        (calc($title-width + $title-slop) - $cb-width)
        ($title-height + $cb-width),
      /* 3 inner Title Height */ calc((100% - $cb-width) - $border-radius)
        ($title-height + $cb-width),
      /* 4 inner right top width to end*/ calc(100% - $cb-width)
        calc(($title-height + $cb-width) + $border-radius),
      /* 5 inner right top height*/ calc(100% - $cb-width)
        calc((100% - $border-radius) - $cb-width),
      /* 6 inner bottom height*/ calc(100% - $border-radius - $cb-width)
        calc(100% - $cb-width),
      /* 7 inner bottom width*/ calc(100% - $bottom-left-width + $cb-width)
        calc(100% - $cb-width),
      /* 8 inner bottom space*/
        calc(100% - $bottom-left-width - $bottom-space-slope)
        calc(100% - calc($bottom-space-height + $cb-width)),
      /* 9 inner bottom space*/ calc($bottom-left-width + $bottom-space-slope)
        calc(100% - calc($bottom-space-height + $cb-width)),
      /* 10 inner bottom space*/ calc($bottom-left-width - $cb-width)
        calc(100% - $cb-width),
      /* 11 inner bottom space*/ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ $border-radius 100%,
      /* outer junction point */ 0% calc(100% - $border-radius) /* end point */
    );
    /*End Content Border Path */
  }
  .content-border-none-bt-space {
    background-color: rgba(var.$primary-color, 0.5);
    min-height: 200px;
    width: 100%;
    height: calc(100% + 3px);
    z-index: -1;

    /* Content Border Path */
    clip-path: polygon(
      evenodd,
      /**
        //origin clip path
        clip-path: polygon(evenodd, 0% 0%, 21.26% 0%, 32.24% 13.98%, 95.63% 13.98%, 100% 28.27%, 100% 89.74%, 95.63% 100%, 62.63% 100%, 53.9% 92.23%, 39.51% 92.3%, 32.24% 100%, 4% 100%, 7.8% 94.72%, 3.76% 84.99%, 3.9% 6.5%, 19.88% 6.5%, 30.02% 20.22%, 92.74% 20.22%, 95.75% 28.27%, 95.83% 88.04%, 92.74% 93.72%, 62.49% 94.72%, 54.62% 86.02%, 39.32% 85.71%, 32.24% 94.72%, 7.8% 94.72%, 4% 100%, 0% 88.99%);
        background-color: #6beb47;

        */
        /*Outer Path */ 0% 0%,
      /* 1 outer left start point*/ $title-width 0%,
      /* 2 outer (Title Width) */
        calc($title-width + $title-slop - $title-slope-adjust-point)
        $title-height + $title-slope-adjust-point,
      /* 3 outer Title Height */ calc(100% - $border-radius) $title-height,
      /* 4 outer right top width to end*/ 100%
        calc($title-height + $border-radius),
      /* 5 outer right top height*/ 100% calc(100% - $border-radius),
      /* 6 outer bottom height*/ calc(100% - $border-radius) 100%,
      /* 7 outer bottom width*/ $border-radius 100%,
      /* outer junction point */ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ calc($cb-width)
        calc(100% - $border-radius - $cb-width),
      /* end point */ /*Inner Path*/ $cb-width $cb-width,
      /* 1 inner left start point*/
        ($title-width - $cb-width + $title-slope-adjust-point) $cb-width,
      /* 2 inner (300px,Title Width */
        (calc($title-width + $title-slop) - $cb-width)
        ($title-height + $cb-width),
      /* 3 inner Title Height */ calc((100% - $cb-width) - $border-radius)
        ($title-height + $cb-width),
      /* 4 inner right top width to end*/ calc(100% - $cb-width)
        calc(($title-height + $cb-width) + $border-radius),
      /* 5 inner right top height*/ calc(100% - $cb-width)
        calc((100% - $border-radius) - $cb-width),
      /* 6 inner bottom height*/ calc(100% - $border-radius - $cb-width)
        calc(100% - $cb-width),
      /* 7 inner bottom width*/ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ $border-radius 100%,
      /* outer junction point */ 0% calc(100% - $border-radius) /* end point */
    );
    /*End Content Border Path */
  }

  .title {
    $font-size: 29px;

    .font-size {
      font-size: $font-size;
    }

    width: $title-width;
    font-size: $font-size;
    color: white;
    // font-weight: 200;
    font-family: var.$font-gkktt;
    // background-color: red;
    // margin-left: 5px;
    // margin-top: 3px;
    // background-color: rgba(var.$dark-color, 0.3);
  }

  .wide-top-space {
    width: calc(100% - 160px) !important;
    height: $title-height;
  }
  .top-space {
    width: calc(100% - $title-width);
    height: $title-height;
  }
  .body {
    // background-color: rgba(var.$dark-color, 0.3);

    .header-font {
      font-size: 20px;
      text-align: center;
      // font-weight: 800;
      // font-family: "NotoSansJP";
    }

    .body-font {
      font-size: 18px;
      font-family: "NotoSansJP";
      line-height: 22px;
    }

    .publish-icon {
      width: 28px;
      height: 9px;
      background-image: url("../icons/publish.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      margin-top: 4px;
      position: relative;

      .publish-up-arrow {
        transition: all 0.5s ease;
      }

      // background-color: red;
      &:hover .publish-up-arrow1 {
        margin-top: -18px;
      }

      &:hover .publish-up-arrow2 {
        margin-top: -10px;
      }
    }

    %publish-up-arrow {
      width: 14px;
      height: 8px;
      background-image: url("../icons/publish_up_arrow.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      left: 7px;
      top: 1px;
    }

    .publish-up-arrow1 {
      @extend %publish-up-arrow;
    }

    .publish-up-arrow2 {
      @extend %publish-up-arrow;
    }

    .edit-icon {
      width: 38px;
      height: 32px;
      // margin-top: 2px;
      position: relative;
      background-image: url("../icons/edit.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        // margin-top: 2px;
        background-image: url("../icons/settings_icon_hover.svg");
      }
    }

    .pen-icon {
      width: 50px;
      height: 40px;
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        .PenToSquareSolid1 {
          transition: 0.3s;
          transform: translate(7px, -62%);
        }

        &::after {
          z-index: 2;
          opacity: 1;
        }
      }
    }

    .detail-more-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/detail_more.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        transition-delay: 0.2s;
        background-image: url("../icons/detail_more_hover.svg");
      }
    }

    .delete-icon {
      display: inline-block;
      width: 30px;
      height: 40px;
      margin-top: 3px;
      //background-image: url("../icons/delete.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      position: relative;
      &::after {
        background-color: var.$dark-color;
        content: "削除";
        width: max-content;
        padding: 2px 5px;
        border: 1px solid var.$primary-color;
        position: absolute;
        color: var.$light-color;
        font-size: 11px;
        text-align: center;
        left: -37px;
        bottom: 40px;
        z-index: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        .trash-can-solid-2 {
          margin-top: 3px;
          transition: 0.3s;
          transform: matrix(0.829, -0.545, 0.545, 0.829, -1, 0.878);
          fill: var.$primary-color;
        }
        .trash-can-solid {
          fill: var.$primary-color;
        }
        &::after {
          z-index: 2;
          opacity: 1;
        }
      }

      // background-color: red;
    }

    .parameter-delete-icon {
      cursor: pointer;
      z-index: 1;
      position: relative;

      &::after {
        background-color: var.$dark-color;
        content: "削除";
        width: max-content;
        padding: 2px 5px;
        border: 1px solid var.$primary-color;
        position: absolute;
        color: var.$light-color;
        font-size: 11px;
        text-align: center;
        left: -20px;
        bottom: 30px;
        z-index: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        .trash-can-solid-2 {
          z-index: 5;
          margin-top: 3px;
          transition: 0.3s;
          transform: matrix(0.829, -0.545, 0.545, 0.829, -1, 0.878);
          fill: var.$primary-color;
        }
        .trash-can-solid {
          fill: var.$primary-color;
        }
        &::after {
          z-index: 2;
          opacity: 1;
        }
      }
    }

    .arrow-icon {
      width: 16px;
      height: 18px;
      background-image: url("../icons/arrow.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .uncheck-icon {
      width: 25px;
      height: 25px;
      background-image: url("../icons/uncheck.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .check-icon {
      width: 25px;
      height: 25px;
      background-image: url("../icons/check.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .dropdown-icon {
      width: 16px;
      height: 16px;
      background-image: url("../icons/dropdown.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .copy-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/copy.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .plus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .minus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/minus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      rotate: 45deg;
    }
    .grey-minus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/grey_minus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .pink-plus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/pink-plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .random-plus {
      width: 30px;
      height: 30px;
      background-image: url("../icons/random-plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      &:hover {
        background-image: url("../icons/random-plus-hover.svg");
      }
    }
    .random-plus-disable {
      width: 30px;
      height: 30px;
      background-image: url("../icons/random-plus-disable.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
    }
  }

  .bottom {
    .space-width {
      width: $bottom-space-width;
    }

    .font-size {
      font-size: 25px;
    }

    .left-arrow {
      width: 50px;
      height: 15px;
      background-image: url("../icons/left-arrow.svg");
      background-repeat: no-repeat;
      margin-top: 27px;
      cursor: pointer;
      // background-color: red;
    }

    .left-arrow:hover {
      background-image: url("../icons/left-arrow-filled.svg");
    }

    .right-arrow {
      width: 50px;
      height: 15px;
      background-image: url("../icons/left-arrow.svg");
      background-repeat: no-repeat;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-top: 27px;
      cursor: pointer;
      // background-color: red;
    }

    .right-arrow:hover {
      background-image: url("../icons/left-arrow-filled.svg");
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.content {
  @include content();
}

.marketplace-content {
  @include content($title-width: 370px);
}

.persona-market-content {
  @include content($title-width: 420px);
}

.persona-up-content {
  @include content($title-width: 310px);
}

.drawchain-create-content {
  @include content($title-width: 370px);
}

.content-wide-title {
  @include content($title-width: 320px);
}
.content-wider-title {
  @include content($title-width: 380px);
}

.update-history-content {
  @include content($title-width: 264.12px);
}

.users-content {
  @include content(2px, 12px, 275.56px);
}

.setting-content {
  @include content(2px, 12px, 270px);
}
/** end content */
.login-box {
  width: 910px;
  height: 510px;
  // background-color: var.$dark-color;
  // border: 1px solid rgba(36, 184, 192, 0.5);
  margin: auto;

  // @media (max-width: 1024px) {
  //     height: 100%;
  //     width: 85%;
  //     margin-bottom: auto;
  // }
}

/** Input Classes */
.activate-on-hover:hover {
  opacity: 1;

  .text-box {
    opacity: 1;
  }
}

.hide-placeholder:focus::placeholder {
  color: transparent !important;
}

.smaller-placeholder::placeholder {
  font-size: 15px !important;
  // color: rgba(white, 0.6) !important;
  color: #9a9a9a;
}

.large-placeholder::placeholder {
  font-size: 17px !important;
}

.lighter-placeholder::placeholder {
  color: rgba(white, 0.4) !important;
}

.center-placeholder {
  padding-bottom: 0.5% !important;
}
.center-placeholder::placeholder {
  text-align: center !important;
}

.left-placeholder::placeholder {
  text-align: left !important;
}

.inherit-placeholder::placeholder {
  color: inherit !important;
  font-size: inherit !important;
}

.hiragino-sans-placeholder::placeholder {
  font-family: "Hiragino Sans" !important;
}
// These classes were written because I do not know how to set minus margin with
// bootstrap clases, please forgive me for sinning.
.font-18px-impt {
  font-size: 18px !important;
}
.minus-margin {
  margin-top: -7px;
}

.person-icon {
  background-color: transparent;
  border: 0;
  position: relative;
  z-index: 1;

  &::after {
    background-color: var.$dark-color;
    content: "ペルリア追加発行";
    width: max-content;
    padding: 2px 5px;
    border: 1px solid var.$primary-color;
    position: absolute;
    color: var.$light-color;
    font-size: 11px;
    text-align: center;
    left: -55px;
    bottom: 25px;
    z-index: 1;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    path {
      opacity: 1;
    }
    &::after {
      z-index: 2;
      opacity: 1;
    }
  }
}

.upload-icon {
  background-color: transparent;
  border: 0;
  position: relative;
  z-index: 1;

  &::after {
    background-color: var.$dark-color;
    content: "売り出し設定";
    width: max-content;
    padding: 2px 5px;
    border: 1px solid var.$primary-color;
    position: absolute;
    color: var.$light-color;
    font-size: 11px;
    text-align: center;
    left: -55px;
    bottom: 25px;
    z-index: 1;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    path {
      opacity: 1;
    }
    &::after {
      z-index: 2;
      opacity: 1;
    }
  }
}

// text box
@mixin text-input($radius: 12px, $border-width: 1.5px) {
  // $input-path: calc(100% - $radius) 100%, 100% 75%, 100% 25%,
  //   calc(100% - $radius) 0%, $radius 0%, 0% 25%, 0% 75%, $radius 100%;
  $input-path: calc(100% - 12px) 100%, 100% calc(100% - 12px), 100% 12px,
    calc(100% - 12px) 0%, 12px 0%, 0% 12px, 0% calc(100% - 12px), 12px 100%;
  // all: unset;
  position: relative;
  min-width: 100px;
  height: 40px;

  &:focus-within {
    opacity: 1;
    .text-box {
      background-color: #24b8c0;
    }
  }

  &:hover {
    opacity: 1;
    .text-box:not(.disabled) {
      background-color: #24b8c0;
    }
    path {
      opacity: 1;
    }
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .text-box {
    position: absolute;
    top: 0px;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: rgba(var.$primary-color, 0.8);
    clip-path: polygon($input-path);

    &::after {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: $border-width;
    }
  }

  .error-border {
    background-color: rgba(var.$danger-color, 0.4); //border opacity
  }
  .inactive {
    background-color: rgba(#7a7373, 0.5);
  }
  .input {
    position: relative;
    color: white;
    // font-size: 20px;
  }

  .textarea {
    position: absolute;
    color: white;
    left: 0;
    resize: none;
    padding-block: 10px;
  }

  .text-form-control-value {
    box-sizing: border-box;
    color: var.$highlight-color;
    font-family: "ethnocentric";
    font-size: 20px;
    text-align: center;
    padding: 0px 20px;
  }

  .text-form-control {
    box-sizing: border-box;
    font-size: 20px;
    padding: 0px 20px;
  }

  .form-control-right-icon {
    position: absolute;
    right: 12px;
    top: 5px;
  }

  .form-control-left-icon {
    position: absolute;
    left: 15px;
    top: 7px;
  }

  .text-form-control-border {
    background-color: #020202;
  }

  %error {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var.$danger-color;
    position: absolute;
    right: 0;
    bottom: -22px;
    font-family: "NotoSansJP";
  }

  .error {
    @extend %error;
  }

  .error-without-right {
    @extend %error;
    right: unset;
    bottom: -25px;
  }

  .error-left-align {
    @extend %error;
    left: 0;
    overflow: visible;
  }

  input,
  textarea {
    // all: unset;
    @extend %unset-input;
    caret-color: #24b8c0;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding: 0 16px;
    font-family: "Europa";
    font-size: 14px;
    // background-color: antiquewhite;
    // font-size: 20px;
    // text-align: center;
    &::placeholder {
      font-size: 14px;
      color: #9a9a9a;
      vertical-align: middle;
      font-family: inherit;

      // height: 100%;
      // line-height: normal;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }

  .form-control-left-icon ~ input,
  .form-control-left-icon ~ textarea {
    padding-left: 40px;
  }

  .form-control-right-icon ~ input,
  .form-control-right-icon ~ textarea {
    padding-right: 40px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input {
  @include text-input();
}
.text-input-lesser-radius {
  @include text-input($radius: 10px);
}
.text-input-bigger-border {
  @include text-input($radius: 10px, $border-width: 2.5px);
}
.text-input-big-border {
  @include text-input($radius: 16px, $border-width: 2px);
}
.text-input-lesser-opacity {
  @include text-input();

  opacity: 0.6;
  input {
    caret-color: rgba(var.$primary-color, 0.6);
  }
}
.text-input-white-caret {
  @include text-input();

  input {
    caret-color: white;
  }
}

.button-input {
  @include text-input();

  &:focus-within {
    opacity: 1;
    .text-box {
      background-color: rgba(#7a7373, 0.5);
    }
  }

  &:hover {
    opacity: 1;
    .text-box:not(.disabled) {
      background-color: rgba(#7a7373, 0.5);
    }
    path {
      opacity: 1;
    }
  }

  &:active {
    opacity: 1;
    .text-box:not(.disabled) {
      background-color: rgba(#a8a1a1, 0.5);
    }
  }
  input[data-exist="0"] {
    font-family: "NotoSansJP-light" !important;
  }

  input[data-exist="1"] {
    font-family: "ethno-light" !important;
    font-weight: 300;
  }
}

//end text box

//Login Input
.login-input {
  $radius: 16px;
  $border-width: 1px;
  $input-path: calc(100% - $radius) 100%, 100% 75%, 100% 25%,
    calc(100% - $radius) 0%, $radius 0%, 0% 25%, 0% 75%, $radius 100%;

  $button-radius: 16px;
  $wrapper-border-width: 3.5px;
  $left-wrapper: calc($button-radius + $wrapper-border-width) 100%,
    $wrapper-border-width 75%, $wrapper-border-width 25%,
    calc($button-radius + $wrapper-border-width) 0%, $button-radius 0%, 0% 22%,
    0% 78%, $button-radius 100%;
  // all: unset;
  position: relative;
  min-width: 100px;
  min-height: 30px;
  opacity: 0.6;

  &:focus-within {
    opacity: 1;
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .left-wrapper {
    position: absolute;
    width: 25px;
    height: 55px;
    clip-path: polygon($left-wrapper);
    background-color: var.$primary-color;
    margin-left: -6px;
  }

  .text-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(var.$primary-color, 0.5);
    clip-path: polygon($input-path);

    &::after {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: $border-width;
    }
  }

  .right-wrapper {
    position: absolute;
    width: 25px;
    height: 55px;
    clip-path: polygon($left-wrapper);
    background-color: var.$primary-color;
    margin-left: 95%;
    transform: scaleX(-1);
  }

  .input {
    position: absolute;
    color: white;
  }

  input {
    // all: unset;
    @extend %unset-input;

    height: 100%;
    width: 100%;
    z-index: 1;
    // padding: 0 70px 0;
    padding-left: 50px;
    // text-align: center;

    &::placeholder {
      // text-align: center;
      letter-spacing: 0px;
      color: #9a9a9a;
      font-size: 17px;
    }
  }
}

//End Login input

//input icon
.icon {
  background-image: url(../images/user-solid.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20%;
  padding: 10px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.icon-lock {
  background-image: url(../images/lock-solid.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20%;
  padding: 13px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.input-row {
  position: relative;
}

//end input icon

// search input box
.search-input {
  $radius: 10px;
  $slope: 40px;
  $inner-start-point: 3px;
  opacity: 0.7;

  .search-box {
    clip-path: polygon(
      calc(100% - $radius) 100%,
      100% calc(100% - $radius),
      100% $radius,
      calc(100% - $radius) 0%,
      0% 0%,
      $slope 100%
    );
    background-color: rgba(var.$primary-color, 0.5);
    min-width: 100px;
    min-height: 30px;
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 50px;

    .input {
      position: static;
    }

    .search-icon {
      z-index: 1;
      margin-right: 10px;
    }
  }

  &:focus-within {
    opacity: 1;
    .search-box {
      opacity: 1;
      background-color: #24b8c0;
      background-color: #24b8c0;
    }
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .search-box::after {
    content: "";
    position: absolute;
    inset: 2px;
    clip-path: polygon(
      calc(100% - $radius) 100%,
      100% calc(100% - $radius),
      100% $radius,
      calc(100% - $radius) 0%,
      $inner-start-point 0%,
      $slope 100%
    );
    background-color: var.$dark-color;
  }

  .input {
    height: 95%;
    position: absolute;
    color: white;
    // caret-color: rgba(var.$primary-color, 1);
    caret-color: #24b8c0;
  }

  .input::placeholder {
    color: white;
  }

  input {
    all: unset;
    height: 100%;
    width: 100%;
    padding-left: 0px;
    z-index: 1;
  }
}

// end search input box

/** End Input Classes */

/** Button Classes */

//base button
@mixin button($font-family: var.$font-gkktt, $font-size: 18px) {
  $border-width: 1px;
  $button-radius: 12px;
  $button-path: calc(100% - $button-radius) 100%, 100% 75%, 100% 25%,
    calc(100% - $button-radius) 0%, $button-radius 0%, 0% 25%, 0% 75%,
    $button-radius 100%;

  $wrapper-border-width: 4px;
  $button-wrapper-path: calc($button-radius + $wrapper-border-width) 100%,
    $wrapper-border-width 75%, $wrapper-border-width 25%,
    calc($button-radius + $wrapper-border-width) 0%, $button-radius 0%, 0% 22%,
    0% 78%, $button-radius 100%;

  position: relative;
  min-width: 100px;
  min-height: 20px;
  background-color: transparent;
  border: none;

  // width: 100%;
  // height: 100%;

  cursor: pointer;
  // background-color: red;

  &:disabled {
    cursor: not-allowed;
  }

  .left-wrapper {
    position: absolute;
    clip-path: polygon($button-wrapper-path);
    background-color: #e45a1b;
    inset: 1px;
  }

  .right-wrapper {
    position: absolute;
    clip-path: polygon($button-wrapper-path);
    background-color: #e45a1b;
    inset: 1px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .inner {
    position: absolute;
    background-color: var.$btn-primary-color;
    clip-path: polygon($button-path);
    inset: 1px;
    margin-left: 7px;
    margin-right: 7px;
    // left: 8px;
    // right: 8px;
  }

  .inner::after {
    content: "";
    position: absolute;
    background-color: var.$dark-color;
    clip-path: polygon($button-path);
    inset: $border-width;
  }

  .text {
    color: var.$light-color;
    font-size: $font-size;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-family;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    path {
      fill: var.$dark-color;
    }
  }

  .left-icon {
    position: absolute;
    left: 20px;
    top: 3px;
  }

  .box-button {
    color: var.$light-color;
    font-size: $font-size;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Ethno";
    width: 100%;
    text-align: center;
  }
}

%button {
  @include button();
}

//end base button

//primary button
.button-primary {
  @include button();

  .inner {
    background-color: rgba(var.$primary-color, 0.5);
  }

  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      // background-color: var.$primary-color;
      @include primary-gradient;
    }

    .text {
      color: var.$dark-color;
    }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end primary button

//cancel button
.button-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  .text {
    color: var.$light-color;
    font-size: 16px;
    font-family: var.$font-gkktt;
  }

  &:hover {
    .text {
      color: var.$primary-color;
    }
  }
}
//end cancel button

//grey (disabled?) button
.button-grey {
  @include button();

  .inner {
    background-color: rgba(#7a7373, 0.5);
  }
  .left-wrapper {
    background-color: #7a7373;
  }

  .right-wrapper {
    background-color: #7a7373;
  }
  .text {
    color: #7a7373;
  }
  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      background-color: var.$primary-color;
    }

    .text {
      color: var.$dark-color;
    }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end grey button

//primary button
.button-dialog {
  @include button($font-family: "ethno-light", $font-size: 15px);
  .text {
    margin-top: -3px;
  }

  .inner {
    background-color: rgba(var.$primary-color, 0.3);
  }

  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      background-color: var.$primary-color;
      @include primary-gradient();
    }

    // .text {
    //   color: var.$dark-color;
    // }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end primary button

//menu button
%button-menu-state {
  .left-wrapper {
    background-color: #e45a1b;
  }

  .inner {
    background-color: #e45a1b;
  }

  .inner::after {
    @include primary-gradient;
  }

  .right-wrapper {
    background-color: #e45a1b;
  }

  .text {
    color: var.$light-color;
  }

  .box-button {
    color: var.$btn-primary-color;
  }
}

.button-menu {
  @extend %button;

  .text {
    font-size: inherit;
  }

  .inner {
    box-sizing: content-box;
    background-color: rgba(var.$primary-color, 0.3);
  }

  &:hover {
    @extend %button-menu-state;
  }
}

.selected-button-menu {
  @extend %button;
  @extend %button-menu-state;
}

//end menu button

// plus button

.button-plus {
  background-color: transparent;
  border: 0;
  position: relative;
  &::after {
    background-color: var.$dark-color;
    content: "追加";
    width: max-content;
    padding: 2px 5px;
    border: 1px solid var.$primary-color;
    position: absolute;
    color: var.$light-color;
    font-size: 11px;
    text-align: center;
    left: -30px;
    bottom: 20px;
    z-index: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    path {
      stroke: var.$primary-color;
    }
    &::after {
      z-index: 2;
      opacity: 1;
    }
  }
}

// end plus button

// increase and decrease buttons
.in-de-btn {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  // &:hover {
  //   path {
  //     stroke: var.$primary-color;
  //   }
  //   .path {
  //     fill: var.$primary-color;
  //   }
  // }
}
// end increase and decrease buttons

/** select persona button **/

.button-select-persona {
  $input-path: calc(100% - 12px) 100%, 100% calc(100% - 12px), 100% 12px,
    calc(100% - 12px) 0%, 12px 0%, 0% 12px, 0% calc(100% - 12px), 12px 100%;
  min-width: 196px;
  width: 296px;
  height: 40px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(#7a7373, 0.5);
    clip-path: polygon($input-path);
  }

  &:disabled {
    background-color: red;
  }

  &:not(.disabled):hover:before,
  &:not(.disabled):focus::before {
    background-color: #24b8c0;
  }

  button {
    position: absolute;
    border: none;
    background-color: transparent;
    padding: 10px 16px;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    clip-path: polygon($input-path);
    inset: 1.5px 1px 1.5px 2px;
    background-color: var.$dark-color;
  }
}

/** end select persona button **/

/** End Button Classes */

//box
@mixin message-box($box-slope: 24px, $side-path-width: 38px) {
  position: relative;
  $slope: $box-slope;

  .container {
    clip-path: polygon(
      calc(5% + 2px) 3px,
      calc(95% - 2px) 3px,
      calc(100% - 4px) calc(10% + 2px),
      calc(100% - 4px) calc(90% + -2px),
      calc(95% - 3px) calc(100% - 3px),
      calc(5% + 2px) calc(100% - 3px),
      3px calc(90% - 2px),
      3px calc(10% + 2px)
    );
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    width: 100%;
    height: 100%;
  }

  .outside-box-top {
    // clip-path: polygon(
    //   $slope 0,
    //   calc(100% - $slope) 0,
    //   100% $slope,
    //   100% calc(100% - $slope),
    //   calc(100% - $slope) 100%,
    //   $slope 100%,
    //   0 calc(100% - $slope),
    //   0 $slope
    // );
    // position: absolute;
    // // overflow: visible;
    // width: 100%;
    // height: 100%;
    // // background-color: rgba(var.$primary-color, 0.7);
    // z-index: -1;

    // clip-path: polygon(5% 0px, 95% 0px, 100% 10%, calc(99% + 2px) 10%, calc(95% - 1px) 1%, calc(5% + 1px) 1%, calc(1% - 2px) 10%, 0px 10%);
    clip-path: polygon(
      0px 40%,
      0px 10%,
      5% 0%,
      95% 0%,
      100% 10%,
      100% 40%,
      calc(100% - 3px) 40%,
      calc(100% - 3px) calc(10% + 2px),
      calc(95% - 2px) 3px,
      calc(5% + 2px) 3px,
      3px calc(10% + 2px),
      calc(0% + 3px) 40%
    );
    background-color: var.$primary-color;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    position: absolute;
  }

  .outside-box-bottom {
    clip-path: polygon(
      0px 40%,
      0px 10%,
      5% 0%,
      95% 0%,
      100% 10%,
      100% 40%,
      calc(100% - 3px) 40%,
      calc(100% - 3px) calc(10% + 2px),
      calc(95% - 2px) 3px,
      calc(5% + 2px) 3px,
      3px calc(10% + 2px),
      calc(0% + 3px) 40%
    );
    background-color: var.$primary-color;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateX(180deg);
  }

  .middle-choose-group-right-box {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 27%;
    z-index: 0;
    top: 33.5%;
    right: 0;
    background-color: var.$dark-color;
  }

  .middle-choose-group-left-box {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 15%;
    z-index: 0;
    top: 46%;
    left: 0;
    background-color: var.$dark-color;
  }

  .middle-box {
    content: "";
    position: absolute;

    display: block;
    width: calc(100% + 2px);
    margin-left: -0.6px;
    height: 20%;
    z-index: 0;
    top: 38%;
    left: 0;
    right: 0;
    // background-color: var.$dark-color;
    // background-color: red;
  }

  .inside-box {
    clip-path: polygon(
      $slope 0,
      calc(100% - $slope) 0,
      100% $slope,
      100% calc(100% - $slope),
      calc(100% - $slope) 100%,
      $slope 100%,
      0 calc(100% - $slope),
      0 $slope
    );
    content: "";
    position: absolute;
    display: block;
    inset: 3px;
    // background-color: var.$dark-color;
  }

  .save-box {
    .body {
      // width: 100%;
      .container-box {
        width: 100%;
        height: 100%;
      }

      .button-row {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-top: 70px;
      }

      .con-button {
        width: 182px;
        height: 40px;
      }
    }

    .path {
      margin-top: 155px;
    }
  }

  .side-path {
    $border-width: 2px;
    $slope: 25px;
    clip-path: polygon(
      0% $border-width,
      0% 0%,
      calc(60% + $border-width) $slope,
      calc(60% + $border-width) 100%,
      60% 100%,
      60% calc($slope + $border-width)
    );
    background-color: var.$primary-color;
    height: 40%;
    width: $side-path-width;
    opacity: 0.7;
    // z-index: 0;
  }
}

.message-box {
  @include message-box();
}

.group-select-message-box {
  @include message-box($box-slope: 35px, $side-path-width: 50px);
}

//end box

//Login Button
.button-text {
  color: black;
  background-image: url("../images/btn-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.button-text:hover {
  color: var.$dark-color;
  background-image: url("../images/btn-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.link {
  text-decoration: none;
}

//End Login Button

.error-border {
  background-color: rgba(var.$danger-color, 0.4) !important; //border opacity
}

.img-container {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.persona-img-container {
  // width: 262px;
  // height: 350px;
  width: 284px;
  height: 325px;
}

.drawchain-thumbnail-container {
  width: 349.95px;
  height: 232.68px;
  border: 1px solid #7a73738f;
}

.drawchain-v-thumbnail-container {
  width: 155px;
  height: 232.68px;
  border: 1px solid #7a73738f;
}

.marketplace-img-container {
  width: 120px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 64px;
    height: 34px;
    object-fit: contain;
  }

  .icon-container {
    position: absolute;
    right: -20px;
    bottom: -14px;
    background-color: #3d3939;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 0;
    z-index: 1;
    &::after {
      background-color: var.$dark-color;
      content: "画像設定";
      width: max-content;
      padding: 2px 5px;
      border: 1px solid var.$primary-color;
      position: absolute;
      color: var.$light-color;
      font-size: 11px;
      text-align: center;
      left: -20px;
      bottom: 30px;
      z-index: 1;
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      path {
        opacity: 1;
      }
      &::after {
        z-index: 2;
        opacity: 1;
      }
    }
  }
}

.image-box {
  z-index: 0;
  $border-width: 1px;
  $button-radius: 10px;
  $path: calc(100% - $button-radius) 100%, 100% 97.5%, 100% 2.5%,
    calc(100% - $button-radius) 0%, $button-radius 0%, 0% 2.5%, 0% 97.5%,
    $button-radius 100%;

  // clip-path: polygon($path);
  // background-color: var.$primary-color;
  min-width: 100px;
  min-height: 20px;

  position: relative;
  background-color: rgba(var.$primary-color, 0.5);
  clip-path: polygon($path);

  .dark-bg {
    // content: "";
    position: absolute;
    background-color: var.$dark-color;
    clip-path: polygon($path);
    // background-image: url("../images/input_name.png");
    inset: $border-width;
  }

  .upload-image {
    position: absolute;
    clip-path: polygon($path);
    inset: $border-width;
    z-index: 2;
    // inset: $border-width;
    // background-image: url("../images/input_name.png");
    // background-repeat: no-repeat;
    // background-size: cover;
  }
}

.cust-modal {
  .overlay {
    width: 100vw;
    height: 100vh;
    inset: 0;
    position: fixed;
    background-color: rgba(1, 1, 1, 0.852);
    z-index: 10;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}

.show-modal {
  .overlay,
  .modal-content {
    opacity: 1;
    visibility: visible;
  }
}

//loading modal

.loading-text {
  animation: loading forwards;
  animation-duration: 10s;
  // position: relative;
  animation-fill-mode: forwards;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.loading-complete-text {
  animation: loading_complete forwards;
  animation-duration: 10s;
  // position: relative;
  animation-fill-mode: forwards;
}

@keyframes loading_complete {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.app-text::after {
  content: "";
  animation: spin forwards;
  animation-duration: 10s;
  position: relative;
  animation-fill-mode: forwards;
}

@keyframes spin {
  0% {
    content: "ローディング. . . .";
    opacity: 1;
  }

  20% {
    content: "ローディング. . . .";
    opacity: 0.5;
  }

  40% {
    content: "ローディング. . . .";
    opacity: 0.1;
  }

  50% {
    content: "完了";
    opacity: 0.1;
  }

  70% {
    content: "完了";
    opacity: 0.7;
  }

  100% {
    content: "完了";
    opacity: 1;
  }
}

.re-text::after {
  content: "";
  animation: animate forwards;
  animation-duration: 10s;
  position: relative;
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    content: "発行中. . . . .";
    opacity: 1;
  }

  20% {
    content: "発行中. . . . .";
    opacity: 0.5;
  }

  40% {
    content: "発行中. . . . .";
    opacity: 0.1;
  }

  50% {
    content: "完了";
    opacity: 0.1;
  }

  70% {
    content: "完了";
    opacity: 0.7;
  }

  100% {
    content: "完了";
    opacity: 1;
  }
}

//Progress Bar
.loader-border {
  display: block;
  position: relative;
  border: 1px solid rgba(var.$primary-color, 0.8);
  border-radius: 10px;
  padding: 1px 1px 1px 1px;
}
.loader {
  display: block;
  position: relative;
  // border: 1px solid var.$primary-color;
  // border-radius: 10px;
  overflow: hidden;
  // animation-fill-mode: forwards;
  // padding: 2px 2px 2px 2px;

  .circle {
    width: 20px;
    height: 100%;
    background: var.$primary-color;
    // background-color: red;
    border-radius: 10px;
    // background-image: url(../icons/elements/progress_bar.svg);
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: 20%;
    // padding: 13px;
    position: absolute;
    // box-sizing: border-box;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  .progress {
    position: absolute;
    left: 1;
    top: 1;
    height: 100%;
    width: 0;
    border-radius: 10px;
    background: var.$primary-color;
    // animation: prog 10s forwards;
    // transition: width 2s;
  }
}

// .loader:after {
//   content: "";
//   position: absolute;
//   left: 1;
//   top: 1;
//   height: 100%;
//   width: 0;
//   border-radius: 10px;
//   background: var.$primary-color;
//   animation: prog 10s forwards;
// }

// @keyframes prog {
//   0% {
//     width: 5%;
//   }

//   100% {
//     width: 100%;
//   }
// }

//End Progress Bar

//Button Opacity
.opacity-button {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

//End Button Opacity

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//End loading modal

//blink animation
.blink {
  animation: blink 0.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

//End blink animation

//choose group
.choose-group-list {
  .name {
    width: 50%;
  }
  .groupno {
    width: 50%;
  }
  //transition: all 0.3s ease-in-out;
  font-family: "Europa";
  .choose-arrow {
    display: none;
  }
  &:hover {
    // background-color: red;
    .name {
      width: 40%;
    }
    .groupno {
      width: 40%;
      text-align: right;
    }
    padding: 0 20px 0;
  }

  &:hover .choose-arrow {
    display: block;
  }
}
//end choose group

// Login Mask Cropping
.crop {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.crop img {
  width: 430px;
  height: auto;
  margin: 15px 0 0 -60px;
}

.error-valid-login {
  font-family: "Europa";
  color: #ff0000;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}

// .login-container {
//   height: 96px;
// }

.select-box {
  border-radius: 5px;
  background-color: white;
  width: 100px;
  // display: none;
  // height: 80px;
  z-index: 10;
  .select-row {
    color: black;
    &:hover {
      cursor: pointer;
      background-color: #7a7373;
    }
  }
}
.dynamic-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, min-content);
  //grid-auto-flow: column;
}

.w-2 {
  width: 2%;
}

.w-80 {
  width: 80%;
}
.w-87 {
  width: 87% !important;
}
.w-90 {
  width: 90%;
}

.text-word-wrap {
  word-wrap: break-word;
}

.gird-table {
  table {
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    overflow: hidden;
    display: block;
    overflow-y: auto;
    table-layout: fixed;
    max-height: 440px;
  }
  .grid-table-body {
    td {
      height: 80%;
    }
  }
  .grid-table-header {
    th {
      height: 40px;
    }
  }
}

.table-row {
  .tr-btn {
    border-radius: 20px;
    padding: 6px 15px;
    cursor: pointer;
  }

  &:hover {
    background: #262626 0% 0% no-repeat padding-box;
    .text {
      color: var.$primary-color;
    }
    .tr-btn {
      background: var.$btn-primary-color;
    }
    .tr-icon {
      fill: white;
    }
  }
}

.choose-perria-table {
  table {
    table-layout: fixed;
    width: 400px;
    border-collapse: collapse;
    text-align: center;
    font-size: 20px;
    position: relative;
  }

  thead {
    position: sticky;
    left: 0;
    top: 0;
    background-color: black;
    z-index: 2;

    th {
      padding-block: 10px;
      color: #e8723c;
      font-family: "ethno-light" !important;
      font-weight: 300;

      &:first-child {
        width: 40%;
      }
      &:last-child {
        width: 60%;
      }
    }
  }

  tbody {
    tr {
      position: relative;

      td {
        padding-block: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 50%;
      }

      &:hover {
        cursor: pointer;
        td {
          &:first-child::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 12px;
            height: 6px;
            background-image: url("../icons/arrow.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: rotate(180deg);
          }

          &:last-child::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            width: 12px;
            height: 6px;
            background-image: url("../icons/arrow.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
    }
  }
}

.custom-table {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }

  th {
    padding-block: 10px;
    padding-left: 10px;
  }

  td {
    padding-block: 6px;
    padding-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th:first-child,
  td:first-child {
    padding-left: 30px;
  }

  td:last-child {
    padding-right: 30px;
  }

  th:last-child {
    padding-right: 40px;
  }

  tbody > tr {
    .action-btn {
      color: var.$light-color;
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 35px;
      background-color: transparent;
      border: none;
    }

    &:hover {
      color: var.$primary-color;
      background-color: #262626;

      .action-btn {
        @include primary-gradient;

        color: var.$light-color;

        path {
          fill: var.$light-color;
          stroke: var.$light-color;
        }
      }
    }
  }
}

.drawchain-table {
  th:last-child,
  td:last-child {
    padding-right: 20px;
  }
}

// text
.label-text {
  color: var.$light-color;
  font-size: 18px;
}

.parameter-label-text {
  color: var.$light-color;
  font-size: 14px;
  font-family: "NotoSansJP-light";
  text-align: center;
}
// end text

.op-1 {
  path {
    opacity: 1;
  }
}

.side-bar-container {
  translate: 0 0;
  scale: 100% 1;
  z-index: 2;
  transition: all 1s ease-in-out;
}

.side-bar-out {
  translate: -100vw 0;
  scale: 200% 1;
}

// .side-bar-in {
//   animation-duration: 3s;
//   animation-name: slidein;
//   animation-timing-function: ease-in;
//   animation-fill-mode: forwards;
// }

// .side-bar-out {
//   animation-duration: 3s;
//   animation-name: slideout;
//   animation-timing-function: ease-out;
//   animation-fill-mode: forwards;
// }

@keyframes slidein {
  from {
    translate: -100vw 0;
    scale: 200% 1;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}

@keyframes slideout {
  from {
    translate: 0 0;
    scale: 100% 1;
  }

  to {
    translate: -100vw 0;
    scale: 200% 1;
  }
}

.vertical-text-container {
  width: 40px;
  height: auto;
  writing-mode: vertical-lr;
}

.vertical-text {
  transform: rotate(-90deg);
  width: 0;
  height: auto;
  direction: rtl;
  letter-spacing: 0.23em;
}

.vertical-en-text {
  font-size: 47px;
  color: var.$primary-color;
  font-family: "ethno-light";
  font-weight: 300;
  line-height: 43px;
  text-align: center;
  span {
    display: block;
  }
}

.side-text-container {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
}

.side-text-container.show {
  z-index: 1;
  opacity: 1;
}

.bg-btn-primary-color {
  background: var.$btn-primary-color;
}

.center-vertical-line {
  position: absolute;
  width: 2px;
  height: 30px;
  background-color: rgba(122, 115, 115, 0.5);
  left: calc(50% - 1px);
}

.fade-in-out {
  opacity: 0;
  visibility: hidden;
}

.fade-in-out.show {
  visibility: visible;
  animation: fadeIn 1s ease-in-out forwards;
}
