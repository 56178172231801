@use "./variables.scss" as var;

.select[data-id] {
  // $input-path: calc(100% - 12px) 100%, 100% 75%, 100% 25%, calc(100% - 12px) 0%,
  //   12px 0%, 0% 25%, 0% 75%, 12px 100%;
  $input-path: calc(100% - 12px) 100%, 100% calc(100% - 12px), 100% 12px,
    calc(100% - 12px) 0%, 12px 0%, 0% 12px, 0% calc(100% - 12px), 12px 100%;
  height: 40px;
  position: relative;
  color: var.$light-color;
  width: 196px;
  font-size: 20px;

  .value {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-inline: 28px 35px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    font-size: 14px;
    cursor: pointer;

    .select-placeholder {
      width: 100%;
      color: var.$secondary-color;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .center-placeholder {
    .select-placeholder {
      text-align: center !important;
    }
  }

  &:not(:focus) {
    .select-placeholder {
      transition-delay: 0.6s;
    }
  }

  .optList {
    position: relative;
    top: -36px;
    z-index: 1;
    padding-inline-start: 16px;
    list-style-type: none;
    opacity: 0;
    width: calc(100% - 35px);
    height: 0;
    max-height: calc(38px * 5);
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    transition: all 0.3s ease-in-out;
    margin-inline: 1px;
    margin-block: -1px;
    font-size: 14px;

    .option {
      height: 0;
      opacity: 0;
      cursor: pointer;
      padding-left: 12px;
      scale: 1 0;
      transition: scale 0.1s ease-in-out 0.3s;
      display: inline-block;
      vertical-align: middle;
      line-height: 38px;
    }
  }

  .optList.selected {
    top: 0px;
    max-height: calc(38px * 4);
    overflow-y: scroll;
  }

  .icon {
    width: 16px;
    height: 16px;
    background-image: url("../icons/dropdown.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: calc(100% - 35px);
    transform-origin: center;
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }

  .select-box {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: calc(40px * 5);
    overflow-y: hidden;
    background-color: rgba(#7a7373, 0.5);
    clip-path: polygon($input-path);
    z-index: 0;
    &::before {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: 1.2px 1px;
    }
    transition: all 0.5s ease-in-out;
  }

  &[data-disabled="1"] {
    .value,
    .icon {
      cursor: default !important;
    }
    .value > span {
      cursor: default !important;
    }
  }

  &:not([data-disabled="1"]):hover {
    .select-box {
      background-color: #24b8c0;
    }
  }

  &:not([data-disabled="1"]):focus {
    .select-box {
      background-color: #24b8c0;
      z-index: 3;
      height: calc(40px * 5);
    }

    .value {
      z-index: 3;
      .select-placeholder {
        visibility: hidden;
      }
    }

    .optList {
      opacity: 1;
      height: auto;
      z-index: 3;
      .option {
        opacity: 1;
        height: 38px;
        scale: 1 1;
      }
    }

    .icon {
      transform: rotate(0deg);
      z-index: 3;
    }
  }

  @for $i from 1 through 5 {
    &:not([data-disabled="1"]):focus {
      .select-box-#{$i} {
        height: calc(40px * $i);
      }
    }
  }
}

// .select-disabled[data-id] {
//   .value {
//     cursor: initial;
//     span {
//       cursor: initial;
//     }
//   }
//   .option {
//     cursor: initial;
//   }
//   .icon {
//     cursor: initial;
//   }
//   &:hover {
//     .select-box {
//       background-color: rgba(#7a7373, 0.5);
//     }
//   }
//   &:focus {
//     .select-box {
//       background-color: rgba(#7a7373, 0.5);
//       z-index: 0;
//       height: inherit;
//     }

//     .value {
//       z-index: 1;
//       .select-placeholder {
//         visibility: visible;
//       }
//     }

//     .optList {
//       opacity: 0;
//       height: 0;
//       z-index: 1;
//       .option {
//         height: 0;
//         opacity: 0;
//         cursor: initial;
//       }
//     }

//     .icon {
//       transform: rotate(90deg);
//       z-index: 1;
//     }
//   }
// }
